// ------------------------------------------------------------
// This file contains all styles related to the input component.
// -------------------------------------------------------------

.form-control:focus,
.form-control:hover {
  outline: 0;
  box-shadow: none;
}

.form-group {
  label {
    color: $gray;
  }
  .form-control, .form-select {
    background-color: $white;
    border: 2px solid $darkSilver;
    &:hover, &:focus {
      background-color: rgba($white, 0.4);
      border: 2px solid $blue;
      color: $gray;
    }
  }
  textarea.form-control {
    height: unset;
  }
}

.react-tel-input {
  direction: ltr;
  .form-control {
    width: 100%;
    background-color: $white;
    border: 2px solid $darkSilver;
    padding: 0.375rem 0.75rem;
    height: 40px;
    color: $gray;
    font-family: $tajawal;
    padding-left: 50px !important;
    &::placeholder {
      color: $gray;
    }
    &:focus {
      ~ .flag-dropdown {
        border-bottom: 1px solid $blue;
        border-radius: 5px;
      }
    }
  }
  .flag-dropdown {
    background: transparent !important;
    border: 1px solid transparent;
    border-radius: 0px;
    border-bottom: none;
    .selected-flag {
      padding: 0 0 0 15px;
      &:hover,
      &:focus,
      &:active,
      &.open {
        background: transparent;
        border-radius: 0px;
      }
    }
  }
  .country-list {
    background-color: $white;
    font-family: $tajawal;
    .country {
      font-size: 14px;
      &:hover,
      &.highlight {
        background-color: $main;
        color: $white;
      }
    }
    .search {
      background-color: $white;
      padding: 10px 10px 6px 10px;
      font-family: $tajawal;
    }
    .search-box {
      border: 2px solid $darkSilver;
      border-radius: 0px;
      background-color: transparent;
      color: $main;
      width: 100%;
      margin-left: 0px;
      padding: 8px 8px 5px;
    }
    .no-entries-message {
      text-align: center;
    }
  }
}
// ----------------------------------------------------------------------------
// This file contains all styles related to the sidebar of the site/application.
// ----------------------------------------------------------------------------

.sections-sidebar {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 290px;
  background: $culturedSilver;
  padding: 15px;
  border-right: 1px solid $darkSilver;
  border-top: 1px solid $darkSilver;
  border-left: 1px solid $darkSilver;
  overflow: scroll;
  -ms-overflow-style: none; 
  scrollbar-width: none;
  transition: all 0.4s;
  margin-top: 70px;
  &::-webkit-scrollbar { 
    display: none;
  }
  &.toggled {
    right: -420px;
  }
  .item {
    background-color: $white;
    border: 2px solid $darkSilver;
    height: 45px;
    border-radius: 5px;
    padding: 3px 10px 0 10px;
    cursor: pointer;
    margin-bottom: 15px;
    color: $gray;
  }
  .thumbnail {
    margin-bottom: 15px;
    img {
      width: 100%;
    }
  }
}

.settings-sidebar {
  position: absolute;
  left: -290px;
  top: 0;
  bottom: 0;
  padding: 15px;
  width: 290px;
  background: $culturedSilver;
  border-right: 1px solid $darkSilver;
  border-top: 1px solid $darkSilver;
  border-left: 1px solid $darkSilver;
  overflow: scroll;
  -ms-overflow-style: none; 
  scrollbar-width: none;
  margin-top: 70px;
  transition: all 0.4s;
  &::-webkit-scrollbar { 
    display: none;
  }
  &.toggled {
    left: 0;
  }
  .form-group {
    input.form-control {
      height: 36px;
      padding: 0.375rem 0.75rem;
    }
    textarea.form-control {
      height: unset;
    }
  }
}
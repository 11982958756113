// -------------------------------------------------------------
// This file contains all styles related to the button component
// -------------------------------------------------------------

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
[role="button"]:focus {
  outline: 0;
  box-shadow: none;
}

.btn-blue {
  background-color: $blue;
  color: $white;
  &:hover {
    background-color: $white;
    color: $blue;
    border: 1px solid $blue;
  }
}

.btn-navy {
  background-color: $navy;
  color: $white;
  &:hover {
    background-color: $white;
    color: $navy;
    border: 1px solid $navy;
  }
}

.btn-gray {
  background-color: $darkGray;
  color: $white;
  &:hover {
    background-color: $white;
    color: $darkGray;
    border: 1px solid $darkGray;
  }
}

.btn-red {
  background-color: $red;
  color: $white;
  &:hover {
    background-color: $white;
    color: $red;
    border: 1px solid $red;
  }
}

.btn-red-outline {
  background-color: $white;
  color: $red;
  border: 1px solid $red;
  &:hover {
    background-color: $red;
    color: $white;
  }
}

.btn-blue-outline {
  background-color: $white;
  color: $blue;
  border: 1px solid $blue;
  &:hover {
    background-color: $blue;
    color: $white;
  }
}

.btn-navy-outline {
  background-color: $white;
  color: $navy;
  border: 1px solid $navy;
  &:hover {
    background-color: $navy;
    color: $white;
  }
}

.btns-container {
  height: 45px;
  border: 1px solid $blue;
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  button {
    border: none;
    height: 35px;
    border-radius: 0.25rem;
    width: calc(100% / 2);
    &.active {
      background-color: $blue;
      color: $white;
      border: 1px solid $blue;
    }
  }
}

.btn-link {
  color: $navy;
  &:hover {
    color: $navy;
  }
}

.btn-white {
  background-color: $white;
  color: $gray;
  border: 2px solid $darkSilver;
  min-width: 155px;
  &:hover {
    background-color: $white;
    color: $gray;
    border: 2px solid $darkSilver;
  }
}
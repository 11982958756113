// ------------------------------------------------------------
// This file contains all styles related to the tabs component.
// ------------------------------------------------------------

.underlined-tabs {
  border-bottom: 1px solid $darkSilver;
  .nav-item {
    &.show {
      .nav-link {
        color: $blue;
        border-color: $white $white $blue $white;
      }
    }
  }
  .nav-link {
    color: $darkGray;
    &.active {
      color: $blue;
      font-weight: bold;
      border-color: $white $white $blue $white;
    }
    &:hover, &:focus {
      border-color: $white $white $blue $white;
    }
  }
}

.chrome-tab {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  .tab-content {
    position: relative;
    .tab {
      width: 100%;
      height: 45px;
    }
    .content {
      position: absolute;
      top: 10px;
      left: 20px;
      .icon {
        height: 25px;
      }
    }
    .close {
      position: absolute;
      top: 10px;
      right: 20px;
    }
  }
}

// -------------------------------------------------------------------
// This file contains all styles related to the react select component
// -------------------------------------------------------------------

// Select Control(Input)
.select__control,
.react-select__control {
  background-color: $culturedSilver !important;
  border-color: $lightSilver !important;
  border-width: 2px !important;
  width: 250px;
  min-height: unset !important;
  height: 31px;
  .select__input {
    color: $darkGray !important;
  }
  &.select__control--is-disabled {
    border-color: $darkGray;
    .select__indicator-separator {
      background-color: $gray;
    }
  }

  &.select__control--is-focused,
  &.react-select__control--is-focused {
    box-shadow: none;
    border-color: $blue;
  }

  .select__indicator svg {
    cursor: pointer;
    color: $darkGray;
  }

  .select__indicator-separator {
    display: none;
  }
  .select__single-value {
    color: $darkGray;
  }

  .select__placeholder {
    color: $darkGray;
  }

  .select__value-container, .select__value-container--has-value {
    margin-top: -3px;
  }
  .select__indicator {
    margin-top: -4px;
  }
}

// Select Menu
.select__menu,
.react-select__menu {
  z-index: 11 !important;
  .select__menu-list,
  .react-select__menu-list {
    .select__option,
    .react-select__option {
      cursor: pointer;
      padding: 2px 12px 8px 12px;
      &.select__option--is-focused {
        background-color: $culturedSilver;
        color: $darkGray;
      }
      &.select__option--is-selected {
        background-color: $darkGray;
        color: $white;
      }
    }
  }
  .select__menu-list,
  .react-select__menu-list {
    .select__group {
      .select__group-heading {
        margin-bottom: 0.5rem;
        color: $darkGray;
        font-weight: bolder;
        font-size: inherit;
      }
    }
  }
}

// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.cursor-pointer {
  cursor: pointer;
}

.cover-img {
  object-fit: cover;
}

@media (max-width: 767px) {
  .w-50, .w-75 {
    width: 100% !important;
  }
}

.ltr {
  direction: ltr;
}

.mb-30 {
  margin-bottom: 30px;
}

.border-blue {
  border: 1px solid $blue;
}

.input-group {
  .dropdown-toggle {
    background-color: $blue;
    border: 2px solid $blue;      
    &:after {
      margin-right: 0.655em;
      vertical-align: 0.055em;
    }
  }
  .dropdown-item {
    &:active, &.active {
      background-color: $blue !important;
    }
  }
  .input-group-text {
    background-color: $lightSilver;
    border: 2px solid $lightSilver;
    color: $gray;      
    &:after {
      margin-right: 0.655em;
      vertical-align: 0.055em;
    }
  }
}

.no-scroll {
  -ms-overflow-style: none; 
  scrollbar-width: none;
  &::-webkit-scrollbar { 
    display: none;
  }
}

.rawabit-badge {
    position: fixed;
    display: inline-block;
    visibility: visible;
    z-index: 99999;
    top: auto;
    right: 12px;
    bottom: 12px;
    left: auto;
    color: $main;
    background-color: $white;
    border-radius: 3px;
    padding: 10px 8px 8px 8px;
    font-size: 13px;
    opacity: 1;
    text-decoration: none;
    line-height: 1;
    transform: none;
    margin: 0;
    width: auto;
    height: auto;
    overflow: visible;
    white-space: nowrap;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.1);
    font-weight: 400;
    cursor: pointer;
    svg {
      margin-inline-end: 5px;
      height: 10px;
      width: 32px;
      margin-top: -1.5px;
    }
}

._loading_overlay_overlay {
  z-index: 1055;
}

.progressive-img {
  &.img-loading {
    filter: blur(10px);
    clip-path: inset(0);
  }
  &.img-loaded {
    filter: blur(0px);
    transition: filter 0.5s linear;
  }
}
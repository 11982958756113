// -----------------------------------------------------------------------------------
// This file contains all styles related to the payment pages of the site/application.
// -----------------------------------------------------------------------------------

.payment-form {
  width: 100%;
  input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    font-size: 16px;
    width: 100%;
    background: $white;
    box-sizing: border-box;
    direction: ltr;
  }    
  .card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: $white;
    box-sizing: border-box;
    direction: ltr;
  }
}

.ordered-list {
  padding-right: 0rem;
  list-style: none;
  counter-reset: steps;
  li {
    margin-bottom: 0.75rem;
    counter-increment: steps;
    padding-right: 2.5rem;
    padding-top: 0.0625rem;
    position: relative;
    &::before {
      content: counter(steps);
      margin-right: 0.875rem;
      background: #f8f8fb;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.375rem;
      display: inline-grid;
      place-items: center;
      line-height: 1.2em;
      font-size: 0.75rem;
      font-weight: 600;
      border: 1px solid #dadcfb;
      color: #333333;
      margin-right: -2.5rem;
      position: absolute;
      top: 0.1rem;
      line-height: 2.1;
    }
  }
}

.plan-card {
  border: 1px solid $darkSilver;
  border-radius: 6px;
  margin-top: 15px;
  padding: 10px 15px;
  label {
    margin-top: 1.5px;
  }
}
// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.header-logo {
  padding: 4rem 0 2rem 0;
  .logo {
    height: 5rem;
    object-fit: contain;
  }
}

.light-header {
  height: 50px;
  background-color: $darkSilver;
}

.navbar {
  .logo {
    height: 3rem;
    object-fit: contain;
  }
  .nav-item {
    .vr {
      height: 20px;
      width: 1px;
      background-color: rgba(0, 0, 0, 0.4);
      margin: 0 0.6rem;
    }
  }
  @media (max-width: 767px) {
    .nav-item {
      .vr {
        display: none;
      }
    }
  }
}
// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Font family
/// @type List
$tajawal: 'Tajawal', sans-serif !default;

/// Colors
/// @type Color
$main: #092434 !default;
$white: #ffffff !default;
$offWhite: #FAFAFB !default;
$gray: #606060 !default;
$silver: #F9F9F9 !default;
$darkSilver: #F1F1F5 !default;
$lightSilver: #F5F5F5 !default;
$babyBlue: #90E0EF !default;
$blue: #00B4D8 !default;
$navy: #0077B6 !default;
$culturedSilver: #FAFAFA !default;
$darkGray: #828894 !default;
$red: #FF4D4F !default;
$black: #000000 !default;
// -----------------------------------------------------------------
// This file contains all styles related to the offcanvas component.
// ------------------------------------------------------------------

.templates-offcanvas {
  width: 65rem;
  border-left: 1px solid #dee2e6;
  .offcanvas-header {
    height: 60px;
  }
  .offcanvas-body {
    overflow: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
    &::-webkit-scrollbar { 
      display: none;
    }
  }
  .offcanvas-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    padding: 1rem 1rem;
    height: 70px;
  }
}

.projects-offcanvas {
  border-left: 1px solid #dee2e6;
  .offcanvas-header {
    height: 60px;
  }
  .offcanvas-body {
    overflow: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
    &::-webkit-scrollbar { 
      display: none;
    }
  }
  .offcanvas-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    padding: 1rem 1rem;
    height: 70px;
  }
}
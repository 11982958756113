// -----------------------------------------------------------------
// This file contains all styles related to the biography component.
// -----------------------------------------------------------------

.biography-one {
  padding: 3rem 30px 3rem 30px;
  img {
    margin: auto;    
    display: block;
  }
}

.biography-two {
  padding: 3rem 30px 3rem 30px;
}

@media (max-width: 767px) {
  .biography-one, .biography-two {
    padding: 30px 0;
  }
}
// -----------------------------------------------------------
// This file contains all styles related to the list component
// -----------------------------------------------------------

.config-list {
  border: 1px dashed transparent;
  &:hover {
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    border: 1px dashed $blue;
  }
}

.list-modal {
  .icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px dashed transparent;
    &:hover {
      transition: all ease-in-out 0.3s;
      border: 1px dashed $blue;
    }
  }
}
// -----------------------------------------------------------------------------------
// This file contains all styles related to the preview pages of the site/application.
// -----------------------------------------------------------------------------------

.gray-navbar {
  background-color: $darkSilver;
  padding: 20px;
  .preview-icons {
    background-color: $white;
    min-height: 45px;
    min-width: 120px;
    border-radius: 8px;
    padding: 5px;
    li {
      width: 60px;
      height: 45px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      cursor: pointer;
      img {
        height: 28px;
      }
      &.active {
        background-color: $blue;
        padding: 5px;
      }
      &:first-child {
        &.active {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
      &:last-child {
        &.active {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
      }
    }
  }
}

.preview-wrapper {
  padding-top: 70px;
  .desktop {
    transition: all 0.3s ease 0s;
  }
  .mobile {
    box-shadow: 0 0 20px rgba(0,0,0,.04);
    border: 1px solid #eff1f4;
    transition: all 0.3s ease 0s;
    margin: 0 auto;
  }
}
// -----------------------------------------------------------
// This file contains all styles related to the card component
// -----------------------------------------------------------

.white-card {
  background-color: $white;
  border-radius: 20px;
  filter: drop-shadow(0px -4px 12px rgba(0, 0, 0, 0.05));
  padding: 2rem 1.5rem;
  min-height: 400px;
}

.project-card {
  background-color: $white;
  width: 100%;
  height: 220px;
  margin-bottom: 30px;
  border-radius: 5px;
  border: 2px solid $lightSilver;
  position: relative;
  cursor: pointer;
  .card-content {
    height: 165px;
    border-bottom: 2px solid $lightSilver;  
    display: flex;
    align-items: center;
    justify-content: center;
    .badge {
      position: absolute;
      font-weight: 500;
      top: 10px;
      padding: 0.50em 0.65em 0.35em 0.65em;
      &.published {
        left: 10px;
      }
      &.template {
        left: 75px;
      }
    }
  }
  .card-text {
    min-height: 51px;
    padding: 0 15px;
    background-color: $culturedSilver;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 3px 3px;
  }
}

.template-card {
  background-color: $white;
  width: 100%;
  height: 200px;
  margin-bottom: 30px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  .card-content {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    .thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
      border-radius: 5px;
    }
    .badge {
      position: absolute;
      top: 10px;
      left: 10px;
      font-weight: 500;
      padding: 0.50em 0.65em 0.35em 0.65em;
    }
    .card-overlay {
      padding: 0 15px;
      background-color: rgba($black, 0.7);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      transition: opacity ease-in-out 0.2s;
      button {
        min-width: 135px;
        padding: 0.3rem 0.5rem 0.25rem 0.5rem;
      }
    }
  }
  &:hover {
    .card-content { 
      .card-overlay {
        opacity: 1;
      }
    }
  }
  &.blue-border {
    box-shadow: rgba($blue, 1) 0px 0px 0px 1px;
  }   
}

.empty-project-card {
  background-color: $white;
  width: 100%;
  height: 200px;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  cursor: pointer;
  &.blue-border {
    box-shadow: rgba($blue, 1) 0px 0px 0px 1px;
  } 
}

.form-card {
  background-color: $white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  .badge {
    font-weight: 500;
    padding: 0.50em 0.65em 0.35em 0.65em;
  }
  .header {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  .footer {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .item {
    padding: 15px;
    border-radius: 0.25rem;
  }
  .scrollable {
    height: 300px;
    overflow: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
    &::-webkit-scrollbar { 
      display: none;
    }
  }
}

// ---------------------------------------------------------------
// This file contains all styles related to the sections component
// ---------------------------------------------------------------

.sortable-item {
  position: relative;
  padding: 0;
  .section-actions {
    opacity: 0;
    width: 210px;
    min-height: 45px;
    position: absolute;
    background: $white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;    
    transform: matrix(-1, 0, 0, 1, 0, 0);
    margin-left: auto;
    margin-right: auto;
    top: 5px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    ul {
      margin: 0;
      padding: 0;
      li {
        cursor: pointer;
        color: $gray;
        text-align: center;
        margin: 0;
        padding: 0;
        width: calc(210px / 5);
        &:hover {
          color: $navy;
        }
        &:last-child {
          color: $red;
        }
        &:first-child {
          color: $blue;
        }
      }
    }
  }
  &:hover {
    box-shadow: 0 0 0 1px $blue inset;  
    .section-actions {
      opacity: 1;
    }
  }
  &.border {
    border: 1px dashed $blue !important;
  }
  .container {
    max-width: 1140px;
  }
}

.dashed-border {
  border: 1px dashed $blue;
  border-radius: 3px;
  padding: 6px 10px;
  min-width: 485px;
}
// -----------------------------------------------------------------------------
// This file contains basic styles for text.
// -----------------------------------------------------------------------------

.text-gray {
  color: $gray;
}

.text-blue {
  color: $blue;
}

.text-navy {
  color: $navy;
}

.text-red {
  color: $red;
}
// ---------------------------------------------------------------
// This file contains all styles related to the tooltip component.
// ---------------------------------------------------------------

.tooltip {
  .tooltip-inner {
    background-color: $gray;
    font-family: $tajawal;
  }
}
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: $gray;
}
// -------------------------------------------------------------------
// This file contains all styles related to the color picker component
// -------------------------------------------------------------------

.picker {
  position: relative;
  margin-inline-start: 10px;
  .swatch {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    border: 3px solid $white;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .popover {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    border-radius: 9px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
}

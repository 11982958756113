// -----------------------------------------------------------------------------------
// This file contains all styles related to the builder pages of the site/application.
// -----------------------------------------------------------------------------------

.droppable {
  margin-right: 290px;
  transition: all 0.4s;  
  .canvas {
    background-color: $darkSilver;
    padding: 20px;
    .droppable-wrapper {
      overflow: scroll;
      -ms-overflow-style: none; 
      scrollbar-width: none;
      &::-webkit-scrollbar { 
        display: none;
      }
    }
  }
  &.toggled {
    margin-right: 0;
    margin-left: 290px;
  }
}

.component {
  border: 2px solid transparent;
  border-radius: .25rem;
  &:hover {
    background-color: rgb(235, 236, 240);
  }
}

p {
  white-space: pre-wrap;
}

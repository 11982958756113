// -------------------------------------------------------------
// This file contains all styles related to the image component.
// -------------------------------------------------------------

.img-modal {
  .img-wrapper {
    position: relative;
    .overlay {
      background: rgba(0,0,0,0.7);
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      -webkit-transition: all 0.4s ease-in-out 0s;
      -moz-transition: all 0.4s ease-in-out 0s;
      transition: all 0.4s ease-in-out 0s;
    }
    svg, .spinner-border {
      position: absolute;
      top: 50%;
      left: 50%;
      color: $white;
      z-index: 11;
      opacity: 0;
      height: 40px;
      width: 40px;
    }
    svg {
      transform: translate(-50%, -50%);
    }
    &:hover {
      .overlay, svg {
        opacity: 1;
      }
    }
    img {
      margin: auto;
      display: block;
    }
    &.red-border {
      border: 1px solid $red;
    }
    &.loading {
      .overlay, .spinner-border {
        opacity: 1;
      }
    }
  }
}

.img-wrapper {
  border: 1px dashed transparent;
  &:hover {
    transition: all ease-in-out 0.3s;
    border: 1px dashed $blue;
  }
}
// ---------------------------------------------------------------
// This file contains all styles related to the heading component.
// ---------------------------------------------------------------

.heading {
  border: 1px dashed transparent;
  border-radius: 3px;
  &:hover {
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    border: 1px dashed $blue;
  }
}


.heading-form {
  .form-group {
    .btns-container {
      button {
        width: calc(100% / 3);
      }
    }
  }
}